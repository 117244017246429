<template>
  <dx-util-popup
    ref="membershipPlanDetailPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="membershipPlanDetailPopupShown"
    @hidden="membershipPlanDetailPopupHidden"
  >
    <div>
      <dx-data-grid
        id="membershipPlanDetailId"
        :data-source="membershipPlanFeatures"
        key-expr="id"
        :height="'65vh'"
        :allow-column-resizing="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :hover-state-enabled="true"
        @row-inserted="insertRow"
        @row-updated="updateRow"
        @row-removed="removeRow"
      >
        <dx-paging :enabled="true" />
        <dx-editing
            :allow-updating="true"
            :allow-deleting="true"
            :allow-adding="true"
            mode="row"
          />
          <dx-column data-field="feature" caption="Feature" alignment="left" />
          <dx-column data-field="isHighlight" :width="120" />
          <dx-column data-field="active" :width="110" />
          <dx-column type="buttons" caption="Actions" :width="120">
          <dx-button
            name="edit"
            hint="Edit Membership Type Details"
            icon="edit"
            css-class="text-danger"
          />
          <dx-button
            name="delete"
            hint="Delete Membership Type Details"
            icon="trash"
            css-class="text-danger"
          />
        </dx-column>
         <dx-paging :enabled="true" :page-size="30" :page-index="1" />
      </dx-data-grid>
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import membershipPlanDetailService from '@/http/requests/membership/plandetail'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    planData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      membershipPlanFeatures: [],
      form: {
        membershipPlanId: null,
        isHighlight: false,
        feature: '',
        active: true,
      },
    }
  },
  computed: {
    membershipPlanDetailPopup() {
      return this.$refs.membershipPlanDetailPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.form.membershipPlanId = this.planData.id
        this.membershipPlanDetailPopup.show()
        this.getMembershipPlanDetails()
      },
    },
  },
  methods: {
    async getMembershipPlanDetails() {
      const result = await membershipPlanDetailService.getListByFilter(this.planData.id)
      this.membershipPlanFeatures = result.data.body
    },
    membershipPlanDetailPopupShown() {
      this.popupTitle = `Membership Plan Details | ${this.planData.name}`
    },
    membershipPlanDetailPopupHidden() {
      this.membershipPlanFeatures = []
      this.form.membershipPlanId = null
    },
    closePopup() {
      this.membershipPlanDetailPopup.hide()
    },
    async insertRow(e) {
      const item = e.data
      const data = {
        feature: item.feature,
        isHighlight: item.isHighlight,
        membershipPlanId: this.planData.id,
        active: true,
      }
      try {
        await membershipPlanDetailService.create(data)
      } catch (error) {
        //
      }
      this.getMembershipPlanDetails()
    },
    async updateRow(e) {
      const item = e.data
      const data = {
        id: item.id,
        feature: item.feature,
        isHighlight: item.isHighlight,
        membershipPlanId: item.membershipPlanId,
        active: item.active,
      }
      try {
        await membershipPlanDetailService.update(data)
      } catch (error) {
        //
      }
      this.getMembershipPlanDetails()
    },
    async removeRow(e) {
      const id = e.key
      try {
        await membershipPlanDetailService.delete(id)
      } catch (error) {
        //
      }
      this.getMembershipPlanDetails()
    },
    handleSubmit() {
      this.$emit('emit-form-saved')
      this.closePopup()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
